@use '../components/mixin' as *;
.close-button-login-signup{
  left: 10px;
  top:10px;
  font-size: 30px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--white);
}
.close-button-login-signup:focus{
  border: none;
  outline: none;
}

.login-heading {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background-color: rgba(8, 12, 58, 1);
	text-align: center;
	font-style: normal;
	font-weight: 900;
	font-size: 33px;
	line-height: 40px;
	color: rgba(255, 255, 255, 0.85);
	padding: 18px 30px;
	position: relative;
	z-index: 9;
  .logo-text{
    @include mobile {
      position: relative;
      left: 5%;
    }
  }
	img {
		margin-right: 8px;
		width: 50px;
	}
}
.login-form {
	.dropdown-btn {
    img {
      width: 25px;
      height: 25px;
      border-radius: var(--borderRadius);
      margin-right: 5px;
    }
		.dropdown-menu {
      min-width: 115px !important;
      left: 0 !important;
      right: auto;
      background-color: var(--secondary-color);
      color: var(--white-color);
      line-height: 0;
      left: auto;
      right: 0;
      border-radius: var(--borderRadius);
      .language-name {
        p {
          // display: none;
        }
        .name {
          min-width: auto;
          height: auto;
          color: var(--dropdown-color);
          line-height: 14px;
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: start;
          border-radius: var(--borderRadius);
          font-size: 0.813rem;
          font-weight: 700;
          padding: 5px 15px;
          margin: 0;
          position: relative;
          &::after {
            content: "";
            width: 100%;
            height: 2px;
            background: linear-gradient(90deg,rgba(52,57,131,0) 1%,#8980d6 47%,rgba(52,57,131,0) 99%);;
            border-radius: 100%;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
          }
          &.selected, &:hover {
            background: linear-gradient(180deg,#b6b8f4 59.82%,rgba(182,184,244,.505) 109.82%);
            border-radius: var(--borderRadius);
            color: var(--white);
          }
          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            margin-right: 5px;
          }

        }
      }
		}
	}
}
.login-left-section {
	width: 50%;
	float: left;
	position: relative;
	.title {
		text-decoration: "none";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		color: var(--white-color);
		text-decoration: none;
	}
	.sub-title {
		font-weight: 700;
		font-size: 24px;
		display: flex;
		align-items: center;
		text-align: center;
		color: var(--white-color);
		padding-top: 180px;
	}
	img {
		height: 100%;
		aspect-ratio: 1;
		object-fit: cover;
	}
}
.mobile-login-img-box {
  display: none;
}
.login-section {
	display: flex;
	width: 100%;
	height: 100vh;
}
.login-text {
	position: absolute;
	bottom: 55px;
	left: 0;
	right: 0;
	h1 {
		font-size: 60px;
		font-weight: 700;
		color: var(--white-color);
		margin-bottom: 0;
		position: relative;
		width: fit-content;
		margin: 0 auto;
		padding: 0 20px;
		span {
			text-transform: inherit;
			position: relative;
		}
		&::before {
			position: absolute;
			width: 100%;
			height: 22px;
			content: "";
			background: var(--heading-highlight);
			bottom: -1px;
			left: 0;
		}
	}
}
form {
	&.login-form {
		width: 50%;
		float: left;
	}
}
.login-right-section {
	display: flex;
  background: linear-gradient(180deg, rgba(2, 12, 94, 0.4) 0%, #010530 72.61%);
	height: calc(100vh - 106px);
	width: 100%;
	align-items: center;
	justify-content: center;
	overflow: auto;
  z-index: 1;
  position: relative;

  @media screen and (max-width: 756px) {
    & {
      padding: 16% 20px 20px;
      align-items: unset;
      height: calc(100vh - 203px);
    }
  }
	.form-group {
		&:nth-child(1) {
			margin-bottom: 20px;
		}
	}
	.form {
		width: 65%;
		height: calc(100vh - 350px);
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
    .form-group {
      margin-left: 0;
      margin-right: 0;
    }
		input {
			height: 52px;
			color: var(--white_90);
			border: 0;
			background: linear-gradient(
    180deg,
    rgba(182, 184, 244, 0.44015) 0%,
    rgba(182, 184, 244, 0.504835) 100%
  );
			border-radius: var(--borderRadius);
		}
		.form-group {
			width: 100%;
		}
		label {

			font-style: normal;
			font-weight: 900;
			font-size: 25px;
			line-height: 30px;
			color: var(--account-text-color);
			margin-bottom: 15px;
		}
		button {
			margin-top: 16px;
			background: #7377b6;
			border-radius: var(--borderRadius);
			min-height: 52px;
			color: var(--white-color);
			margin-bottom: 35px;
			border: 0;

			font-style: normal;
			font-weight: 700;
			font-size: 22px;
			line-height: 26px;
			text-align: center;
			min-width: 185px;
      width: fit-content;
		}
	}
	.submit-btn {
		display: flex;
		justify-content: center;
		align-items: center;


    @media screen and (max-width: 767.98px) {
      & {
        width: 50% !important;
        margin: auto;
      }
    }
	}
	.forget-link {
		text-decoration: none;
		text-align: right;
		margin-top: 10px;
		display: inline-block;
		width: 100%;

		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		color: var(--white-color);
	}
	.dont-have-ac {

		font-style: normal;
		font-weight: 700;
		font-size: 15px;
		line-height: 24px;
		color: var(--white-color);
		text-align: center;
		a {
			text-decoration: none;
			font-size: 18px;
		}
	}
}
.login-right-section .form input:focus,
.login-right-section .form input:focus-visible {
	outline: none;
	box-shadow: inherit;
}
.error-message {
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.228px;
	color: var(--errorColor);
	margin-left: 0;
	margin-top: 11px;
}
.login-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 44px;
	right: 12px;
	img {
		cursor: pointer;
	}
  @media screen and (max-width: 575px) {
    top: 28px;
  }
}
.login-right-section::before, .login-right-section::after {
	top: 0;
	height: 100%;
	left: 0;
	content: ' ';
	width: 100%;
	display: inline-block;
	position: absolute;
  pointer-events: none;
}

@media screen and (max-width: 1199.98px) {
	.login-heading {
		padding: 29px 0;
	}
}
@media screen and (max-width: 991.98px) {
	.login-text {
		h1 {
			font-size: 40px;
			&::before {
				height: 12px;
			}
		}
	}
}
@media screen and (max-width: 824.98px) {
	.login-right-section {
		.form {
			button {
				margin-bottom: 15px;
			}
			width: 100%;
			height: auto;
			label {
				font-size: 18px;
			}
		}
	}
	.login-section {
		overflow: visible;
    .login-heading {
			.dropdown-btn {
				right: 0;
        position: unset !important;
        img{
          width: 20px;
        }
			}
		}
	}
	.login-left-section {
		width: 100%;
    display: none;
	}
  .mobile-login-img-box {
    display: block;
    position: relative;
  }
	form.login-form {
		width: 100%;
	}
	.login-heading {
		padding: 20px 15px;
		font-size: 25px;
		justify-content: space-between;
	}
	.login-form {
		.login-heading {
			.dropdown-btn {
				right: 0;
			}
		}
	}
}
@media screen and (max-width: 575.98px) {
  .login-right-section {
    display: block;
  }
	.login-heading {
		font-size: 20px;
		img {
			width: 45px;
		}
	}
	.login-text {
		h1 {
			font-size: 30px;
			&::before {
				height: 12px;
			}
		}
		bottom: 25px;
	}
  .login-right-section .submit-btn {
    button {
      font-size: 1rem;
      min-height: 38px;
      padding: 0 10px;
    }
  }
}

.login-text-mobile {
	position: absolute;
  top: 0;
  bottom: 0;
  right: 7px;
  display: flex;
  width: 55%;
	.login-welcome-heading {
		font-size: 1.9rem;
		font-weight: 700;
		color: var(--white-color);
		margin-bottom: 0;
		position: relative;
		text-align: center;
		max-width: 609px;
		margin: 0 auto;
    align-self: center;
	}
	.amazing {
		font-size: 1.8rem;
		position: relative;
		z-index: 9;
		padding: 0 10px;
    z-index: 0;
    margin-top: -5px;
		&::before {
			position: absolute;
			width: 100%;
			height: 10px;
			content: "";
			background: var(--heading-highlight);
			bottom: -1px;
			left: 0;
			z-index: -1;
		}
	}
	p {
		margin-bottom: 0 !important;
		font-weight: normal;
		span {
			font-weight: bold;
			padding-right: 15px;
		}
	}

  @media screen and (max-width: 576.98px) {
    .amazing,.amazing-login {
      font-size: 1.8rem;
    }
  }

  @media screen and (max-width: 492.98px) {
    .login-line {
      display: block; /* makes it have a width */
      content: ""; /* clears default height */
      margin-top: -14px; /* change this to whatever height you want it */
    }
    .amazing {
      &::before {
        height: 8px;
      }
    }
    .amazing,.amazing-login {
      font-size: 1.4rem;
    }
    .login-text-mobile{
      line-height: 120%;
    }
  }

  @media screen and (max-width: 377.98px) {
    .amazing,.amazing-login {
      font-size: 1.2rem;
    }
  }
}
button:disabled{
  opacity: 0.6;
  cursor: not-allowed;
 }
