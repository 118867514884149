 @import '../../../scss/components/mixin';
.button-element {
    border-radius: var(--borderRadius);
    min-width: 91px;
    background: var(--primary);
    font-size: 13px;
    font-weight: 500;
    display: flex;
    gap: 7px;
    padding:5px 20px;
    color: var(--white);
    border: none;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    height: 36px;
    img{
      width: 20px;
    }
    @include mobile{
      padding-left: 12px;
      padding-right: 12px;
    }
    &.neo-active{
      @include activeNeuomorphism;
    }
    &.primary{
      background: var(--primary);
      color: var(--white);
    }
    &.ghost{
      background: transparent;
      color: var(--bodyText);
    }
    &.secondaryGradient{
      @include secondaryGradient;
      color: var(--white);
    }
    &.default{
      background: var(--bgSupportSecondary);
      color: var(--bodyText);
    }
    &:disabled{
      pointer-events: none;
    }
    &:active{
      transform: scale(0.95);
    }

  &.btn-sm {}

  &.btn-lg {}

  &.btn-md {}

}
