@use 'mixin' as *;

.cookie-consent-modal {
  .modal-content {
    color: var(--bodyText);
  }
  .modal-footer {
    button {
      @include PrimaryBtn();
      @include neuomorphism;

      &.success-button {
       background-color: var(--primary);
       @include activeNeuomorphism;
      }
    }
  }
}




.submit-btn-join-contest {
  @include SecondaryBtn();
  box-shadow: none;

}

.btn-modal-join-contest {
  @include SecondaryBtn();
  box-shadow: none;

}

.game-search-modal {
  width: calc(100% - 250px);
  max-width: calc(100% - 250px);
  // background: #212A31;
  margin-left: 250px;
  .modal-content {
    background-color: transparent;
    backdrop-filter: none;
    border: none;
    padding: 0;
  }
  .modal-body {
    background-color: var(--bgSupportMain);
    padding: 20px;
  }

  .btn-close {
    margin-right: 10px;
    position: absolute;
    right: 0px;
    filter: invert(1);
    opacity: 1;
  }

  .modal-dialog {
    width: 77.8%;
    max-width: 77.8%;
    min-width: 77.8%;
    margin: 0 auto;
    @media screen and (max-width: 991px) {
      width: 96%;
    max-width: 96%;
    min-width: 96%;
    }

  }

  .modal-input-group {
    margin: 1rem 0;
    input {
      @include Inputs();
      padding-left: 50px;
      border-radius: 50px;

    }
  }
}

.game-container {
  position: relative;
  height: 100%;
  padding: 0;

  img.game-img {
    aspect-ratio: 1;
    object-fit: cover;
  }

  .all-game-listing {
    padding: 0;
    margin: 0;
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
    gap: 1rem;

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
    }

    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    }

    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    }

    .game-container {
      cursor: pointer;

      &:hover {
        .hover-content {
          display: flex;
        }
      }
    }
  }

  .play-links {
    a {
      color: var(--bodyText);
      text-decoration: none;
    }
  }

  .hover-content {
    display: none;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    align-items: center;
    justify-content: center;

    .play-btn {
      @include SecondaryBtn();
      box-shadow: none;
    }
  }
}


.chips,
.chips:hover {
  background-color: var(--bgSupportSecondary);
  border-radius: var(--borderRadius);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bodyText);
  text-decoration: none;
  gap: 10px;
  font-size: 12px;
  cursor: pointer;
}

.filter-chips {
  display: flex;
  gap: 15px;
  margin-bottom: 1rem;
  overflow-x: auto;

  .chips {
    white-space: nowrap;
  }

  .clear-filter {
    @include SecondaryBtn();
    box-shadow: none;
    text-decoration: none;
  }
  #signinmodal.fade:not(.show){
    opacity: 1;
  }
}









#signinmodal.fade:not(.show) {
  opacity: 1;
}


@include mobile {
  .not-found-box {
    h5 {
      font-size: 0.8rem;
    }
  }
}
