.cms-details{
  p{
    color: var(--gray);
  }
  &.terms-and-conditon{
    padding: 0;
    .heading-text{
      display: none;
    }
    p,li{
      font-size: 12px;
    }
    h3 {
      font-size: 1.05rem;
  }
  }
}
