@use 'mixin' as *;

.parent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &.light{
    // @include animationBlink;
    svg{
      filter: invert(0.8);
    }
  }
}
.screen-loader{
  position: fixed;
  inset: 0;
  height: 100vh;
  z-index: 100000;
  background-color: var(--bodyBg);
  &.backdrop{
    background: var(--backDrop);


  }

}
.page-loader{
  position: fixed;
  height: calc(100vh - 65px);
  background-color: var(--bodyBg);

}
