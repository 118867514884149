@import '../../scss/components/mixin';
.loadmore{
  width: 25%;
  min-width: 300px;
  @include mobile {
   width: 90%;
  }
    .progress {
      background-color: var(--progressBar);

      .progress-bar {
        background-color: var(--primary);
      }
    }

}
.load-more-btn {
  position: relative;
  z-index: 1;
  button {
    @include ClaimBtn();
    background: var(--primary);
    color: var(--white);
    @include text-sm;
    max-width: inherit;
    padding: 5px 50px;
    margin: 0 auto;
    font-weight: 600;
    height: 40px;
  }
}
