@use '../components' as *;

.game-ui {
  margin-top: 20px;
  width: 100%;
  height: calc(100dvh - 68px);
  max-height: 735px;
  min-height: 735px;
  display: flex;
  flex-direction: column;

  .exit-fullscreen {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 24px;
    z-index: 100;
  }

  .exit-fullscreen {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 24px;
    z-index: 10000;
  }

  @include mobile {
    margin-top: 10px;
    min-height: 400px;
    height: calc(100dvh - 152px);
  }

  @media screen and (orientation: landscape) and (max-width: 1200px) {
    margin-top: 10px;
    min-height: unset;
    height: calc(100dvh - 70px);
  }

  @media screen and (orientation: landscape) and (min-height: 900px) {
    height: calc(100dvh - 70px);
    max-height: 738px;
    min-height: 730px;
    width: 100%;

  }

  .game_wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--bgSupportMain);
    border: 0;
    scroll-behavior: auto;
    position: relative;

    .parent {
      position: absolute !important;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0 !important;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
      position: absolute;
      top: 0;
      left: 0;

    }

    &.fullscreen {
      position: fixed;
      inset: 0;
      width: 100%;
      height: 100dvh;
      z-index: 100000;

      iframe {
        height: 100% !important;
      }
    }

  }

  &.theatre {
    background-color: var(--bgSupportMain);
    max-width: 148vh !important;
    margin: auto;
    height: calc(100dvh - 68px);
    max-height: unset;
    // --max-height:873px;
    // max-height: var(--max-height, 500px);

  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn-box-gameplay {
  .game-btns {
    display: flex;
    margin-bottom: 15px;
    margin-top: 0.63rem;
    gap: 1rem;

    .view-all-btn {
      @include SecondaryBtn();
      box-shadow: none;
    }
  }
}
