@use 'mixin' as *;

.logo.header {

  img {
    cursor: pointer;
  }

}

.desktop-logo {
  @media screen and (max-width: 576px) {
    display: none;
  }
}

.mobile-logo {
  display: none;

  &.light {
    filter: invert(0.78);
  }

  @media screen and (max-width: 576px) {
    display: block;
    width: 35px;
  }
}



.parent-section {
  display: flex;

  .header-wrapper {
    display: flex;
    background: var(--bodyBg);
    z-index: 3;
    position: sticky;
    z-index: 1000;
    top: 0;
    width: 100%;

    @include mobile {
      width: 100%;
      max-width: 100%;
    }
  }

  &.sidebar-close {
    .header-wrapper {
      @include mobile {
        width: 100%;
        max-width: 100%;
      }

    }

    .no-games {
      display: none;
    }

    li.accordion-navigation {
      h2 {
        button {
          &:after {
                position: absolute;
                  right: 0;
                  background-color: transparent;
                  background-size: 25px;
                  width: 25px;
                  height: 25px;
          }

          .link {
            display: none;

            @include mobile {
              display: block;
            }
          }
        }
      }
    }

    .left-side--bar {
      width: 78px;

      @include mobile {
        width: 100%;
        // display: none;
      }

      .league-heading {
        display: none;

        @include mobile {
          display: block;
        }
      }

      .top-btn--grp {
        flex-direction: column;
        font-size: 0;
        padding: 0;
        box-shadow: none;

        @include mobile {
          opacity: 0;
          display: none;

        }

        .casino-sport-btn {
          flex-direction: column;
          padding: 0.3rem 10px;

          @include mobile {
            display: none;
          }

          a {
            height: 43px;
          }
        }

        button {

          &.casino-btn,
          &.sports-btn {
            min-height: 44px;
            min-width: 44px;
            background-size: cover;
            background-position: -14px 0px;
            margin: 0 0.3rem;
          }

        }
      }

    }

    .left-section-child {
      padding: 0;

      a,
      .game-heading {
        // padding-left: 0;

        // @include mobile {
        //   padding-left: 26px;
        // }

      }

      .game-heading {
        justify-content: center;

        @include mobile {
          justify-content: flex-start;
        }

        span {
          display: none;

          @include mobile {
            display: block;
          }
        }

      }

      ul {
        li {
          button {
            font-size: 0;
            justify-content: center;
            padding-left: 0;

            @include mobile {
              font-size: initial;
              justify-content: start;
              padding-left: 26px;
            }
          }

          a {
            justify-content: center;

            @include mobile {
              justify-content: start;
            }

            .link-text {
              font-size: 0;
              display: none;

              @include mobile {
                font-size: initial;
                display: block;
              }
            }
          }
        }
      }
    }

    .middle-section {
      width: calc(100% - 100px);
      max-width: calc(100% - 100px);
      // margin-left: 100px;

      @include mobile {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
      }

      .parent-section .header-bg {
        display: table;

        @include mobile {
          width: 100%;
          max-width: 100%;
          margin-left: 0;
        }
      }
    }
  }

}


.header-bg {
  width: 100%;

  .navbar {
    flex-wrap: nowrap;
  }

  .second-header {
    padding: 0.74rem 0;
  }

  .login-signup-btn {
    gap: 10px;


    li {
      .icon-width {
        width: 45px;
        height: 45px;

        @include mobile {
          width: 40px;
          height: 40px;
        }
      }

      .search-wrap {
        @extend .icon-width !optional;
        border: 1px solid var(--borderColor);
        background-color: var(--bgSupportSecondary);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;

        &.light {
          @include innerLightInputNeuomorphism;
        }

        img {
          width: 17px;
        }
      }

      &.auth-details {
        min-height: 45px;
        display: flex;
        gap: 10px;
        align-items: flex-end;


        @include mobile {
          gap: 6px;
          align-items: center;
        }

        .level {
          border: 2px solid transparent;

          @include mobile {
            display: none;
          }

          &.active {
            border: 2px solid var(--dot);
            animation: borderBlink 1s infinite linear;

            @keyframes borderBlink {
              0% {
                border-color: #80ff80;
              }

              30% {
                border-color: #1aff1b;
              }

              70% {
                border-color: #00b301;
              }

              100% {
                border-color: #b3ffb3;
              }
            }

          }

          @extend .icon-width !optional;
          border: 1px solid var(--borderColor);
          background-color: var(--bgSupportSecondary);
          border-radius: 12px;
          padding: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 9px;
            text-transform: uppercase;
            color: var(--white);
            font-weight: 600;
          }

          b {
            color: var(--primary);
            font-size: inherit;
            line-height: 1;
          }
        }

        .user-detail {
          line-height: 1;
          span {
            font-size: 12px;
            color: var(--gray);

            &.username {
              &.balance {
                color: var(--gray);
              }

              color: var(--primary);
              display: flex;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.2;

              @include mobile {
                font-size: 12px;
                justify-content: flex-end;
                padding-right: 4px;
                display: none;
              }
            }

            &.amount {
              @include text-sm;
              color: var(--bodyText);
              margin-right: 10px;
              font-weight: 500;

              @include mobile {
                font-size: 12px;
                font-weight: 400;
              }
            }

          }

        }

        .user-img ,.bordered-icon{
          @extend .icon-width !optional;
          border: 1px solid var(--borderColor);
          background-color: var(--bgSupportSecondary);
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          padding: 0;
          cursor: pointer;
        }
        .user-img{
          @include mobile {
            display: none;
          }
        }


        .level-img {
          background: transparent;
          border: none;
          outline: none;
          @extend .icon-width !optional;
          border-radius: 12px;
          padding: 0;
          overflow: hidden;

          @include mobile {
            display: none;
          }

          img,
          .loading-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          .loading-image {
            @include animationBlink;
          }
        }

        .add-on-icon {
          @extend .icon-width !optional;
          border-radius: 8px;
          display: none;
          align-items: center;
          justify-content: center;
          position: relative;

          @include mobile {
            display: flex;
          }

          .count-dot {
            pointer-events: none;
            position: absolute;
            top: -5px;
            right: -4px;
            background-color: var(--lossAmtColor);
            border-radius: 50%;
            min-width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--white);
            font-size: 11px;
            font-weight: 600;

          }

          &.suitcase {
            @include suitcaseGradient;
          }

          &.trophy {
            @include trophyGradient;
          }

          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
          }

        }
      }

      &.auth-btns {
        border-radius: 14px;
        display: flex;
        gap: 6px;
        align-items: center;
        background-color: var(--primaryDark);

        button {
          border-radius: inherit !important;
          padding-left: 28px;
          padding-right: 28px;
        }

      }
    }
  }


}

.conatiner {
  padding: 0 3vw;
}




.modal-tabs {
  ul.nav-pills {
    border-radius: var(--borderRadius);
    padding: 0.31rem;
    max-width: 380px;
    gap: 10px;

    li {
      width: calc(100% / 2 - 10px);

      &:nth-child(1) {
        margin-left: 0px;
      }

      button {
        font-size: 0.875rem;
        font-style: normal;
        line-height: normal;
        color: var(--bodyText);
        display: flex;
        gap: 0.5rem;
        text-decoration: none;
        padding: 0.938rem 1.25rem;
        align-items: center;
        width: 100%;
        justify-content: center;
        background: var(--bgSupportSecondary);
        @include neuomorphism;

        &.active {
          @include activeNeuomorphism;
          border-radius: var(--borderRadius);
          background: var(--primary);
        }
      }
    }
  }
}

.deposit-withdraw {
  border-radius: var(--borderRadius);
  margin-top: 1rem;

  .acc-number {
    gap: 10px;
    margin-bottom: 10px;

    >div {

      font-size: 14px;

    }
  }

  h6 {
    font-size: 1.125em;
    color: var(--bodyText);
    padding-bottom: 1.25rem;
  }

  p {
    font-size: 0.875em;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  label {
    font-size: 0.875em;

    sup {
      color: red;
    }
  }

  input {
    @include Inputs();
    width: 100%;

  }

  .footer-modal {
    padding: 1rem;
    border-top: 1px solid var(--borderColor);

    ul {
      display: flex;
      gap: 20px;
      padding: 0;
      margin: 0;
      align-items: center;
      list-style: none;

      button {
        @include SecondaryBtn();
        @include activeNeuomorphism;
      }
    }
  }
}



#logoutmodal {
  .logout-btn {
    @include PrimaryBtn();
    background-color: #e9113c;
    color: white;
  }

  .login-sure-btn {
    @include PrimaryBtn();
    background-color: #e9113c;
    color: white;
    border: 2px solid black;
    width: 40%;
    justify-content: center;
  }

  .logout-text {
    font-size: 14px;
  }
}

.view-all-heading {
  padding-top: 10px;
  background-color: var(--bgSupportMain);
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
  background: url('../../../public//images/casino-bg-banner.png');

  .bread-crumb-list {
    span {
      font-size: 12px;
      margin-right: 5px;

      &.last-title {
        color: var(--gray);
      }

    }

  }

  &.light {
    background: var(--bgSupportMain);

    img {
      mix-blend-mode: unset;
      filter: invert(0.25);
    }
  }

  img {
    width: 240px;
    height: 110px;
    overflow: hidden;
    object-fit: contain;
    object-position: center;
    mix-blend-mode: initial;
    transform: scale(1.2);

    img {
      width: 140px;
      height: 70px;
    }
  }

  h2 {
    margin-top: 10px;
    text-transform: capitalize;
    font-weight: 800;
    font-size: 45px;

    @include mobile {
      font-size: 32px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .open-sidebar.sidebar-backdrop {
    position: fixed;
    background: var(--backDrop);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 48;
  }

  .left-side--bar {
    background-color: var(--bodyBg);
  }

  .parent-section {
    display: flex;

  }
}

@media screen and (max-width: 767px) {
  .parent-section .middle-section .parent-section .header-bg {
    width: 100%;
    max-width: 100%;
  }

  .view-all-heading {
    img {
      width: 140px;
      height: 70px;
    }
  }
}

.crypto-dropdown .dropdown-toggle::after {
  display: none;
}

.crypto-dropdown {
  .dropdown-toggle {
    &.show {
      .arrow-down {
        transform: rotate(180deg);
      }
    }
  }

  .arrow-down {
    margin: 0 !important;
    margin-left: 3px !important;
  }
}


.top-border-line {
  border-top: 1px solid var(--borderColor);
}
