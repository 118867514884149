@use 'mixin' as *;

%heading {
 color: var(--bodyText);
 margin: 0;
 padding: 0;
 line-height: 1.2;
 font-weight: 600;
}


.heading-1{
  font-size: 2rem;
   @extend %heading;
   @include mobile{
     font-size: 1.8rem;
   }
}
.heading-2{
  font-size: 1.8rem;
   @extend %heading;
   @include mobile{
    font-size: 1.5rem;
  }
}
.heading-3{
  font-size: 1.6rem;
   @extend %heading;
   @include mobile{
    font-size: 1.3rem;
  }
}
.heading-4{
  font-size: 1.2rem;
   @extend %heading;
}
.para-text{
  font-size: 1rem;
  line-height: 1.3;
  color: var(--bodyText);
  opacity: 0.7;
  @include mobile{
    font-size: 0.8rem;
    line-height: 1.25;
  }
}
.text-muted{
  color: var(--gray);
}
