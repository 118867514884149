.error-message {
	font-style: normal;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: 0.228px;
	color: var(--errorColor);
	margin-top: 11px;
}
.deposit-withdraw--parent{
  .error-message{
    margin-bottom: 10px !important;
  }

}
.view-all-vouchers{
    font-size: 14px;
    text-decoration: underline;
    transition:  all 0.3s;
  &:hover{
    opacity: 0.85;

  }
}
.disabled{
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
// .tooltip-icon {
// 	border-radius: 50%;
// 	border: 1px solid var(--white-color);
// 	width: 20px;
// 	height: 20px;
// 	text-align: center;
// 	position: relative;
// 	margin-left: 5px;
// }
// #tip {
// 	width: 40%;
// }
// .reset-password-header.login-left-section {
// 	width: 100%;
// 	float: none;
// 	.sub-title {
// 		padding-top: 0px;
// 		text-align: center;
// 		display: block;
// 	}
// }
// .reset-password-body.login-right-section {
// 	.form {
// 		height: auto;
// 	}
// }
