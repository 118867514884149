@import '../../scss/components/mixin';
.tabs-btn-wrapper {
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  .gradient-btn {
    border-radius: inherit;
    background: transparent;
    color: var(--gray);
    padding-left: 1rem;
    padding-right: 1rem;
    @include neuomorphism;

    &.active {
      color: var(--white);
      background: var(--primary);
      @include activeNeuomorphism;
    }
  }
}
