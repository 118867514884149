@use 'components';
@use 'pages';
iframe#webpack-dev-server-client-overlay {
  display: none;
}
.form-check-input {
  background-color: var(--bgSupportMain);
  cursor: pointer;
  outline: none;
  &:focus{
    box-shadow: unset
  }
  border: 2px solid;
  border-color: var(--gray);
}
.form-check{
  font-family: inherit;
  label{
    cursor: pointer;
    font-family: inherit;
    text-transform: capitalize;
    // margin-left: -5px;
    position: relative;
    top: 1px;
  }
}

.form-check-input:checked {
  background-color: var(--primary);
  border: 4px solid;
  border-color: var(--checkBoxBorder);
}
.form-check-input:checked[type=radio] {
  background-image: unset
}
.svg-icon{
  fill: var(--textWhite);
}

.final-score{
  background-color: var(--primary);
  font-weight: 600;
  min-width: 25px;
  overflow: hidden;
  padding: 0px 5px;
  border-radius: 5px;
  text-align: center;
}

.sidebar-svg-icon{
  img{
    width: 25px !important;
    height: 25px !important;
  }
}
.Toastify__toast-body{
  .toaster{
    padding: 5px 0px 0px 0px;
  }
}
