@use 'mixin' as *;

.form-input-wrapper {
  background-color: var(--bgSupportTertiary);
  border: 1px solid var(--borderColor);
  padding: 10px 10px;
  border-radius: 12px;

  label {
    color: var(--gray);
    font-size: 14px;
    line-height: 1;
  }

  input,
  select {
    line-height: 1;
    background: transparent !important;
    border: none;
    font-size: 0.875rem !important;
    padding: 5px 0;
    color: var(--bodyText) !important;
    width: 100%;

    &:focus {
      box-shadow: none;
      border: none;
      outline: none;

    }

    option {
      background: var(--bgSupportTertiary);
      color: var(--white);
    }
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  select::-ms-expand {
    display: none;
  }

  .icon-right {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;

    &.select-arrow {
      pointer-events: none;
    }

    img {
      width: 22px;
      height: 22px;
      object-fit: contain;
    }
  }

}

label {
  font-size: 14px;
  color: var(--gray);
}

.checkbox-field {
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;

  input {
    position: relative;
    height: 25px;
    width: 25px;
    accent-color: var(--primary);
    box-shadow: none !important;
    border: 1px solid var(--borderColor);
    cursor: pointer;
    appearance: none;
    background-color: var(--bgSupportTertiary);
    border-radius: 5px;

    &::after {
      display: none;
      position: absolute;
      content: "";
      height: 22px;
      width: 25px;
      background: url('../../../public/icon/checked.png');
      background-size: 46%;
      background-position: center;
      background-repeat: no-repeat;
      filter: invert(1);

    }

    &:checked {
      background-color: var(--primary);

      &::after {
        display: block;
      }
    }
  }
}

.switch-field {
  width: 48px;
  height: 20px;
  border-radius: 50px;
  background-color: var(--grayBgVariant);
  padding: 2px;
  position: relative;

  .round-ball {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background-color: #ccc;
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 50px;
    &::before{
      position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 2px;
        background-color: white;
        -webkit-transition: .3s;
        transition: .3s;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
    }

  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
    input:checked+.round-ball {
      background-color: var(--primary);
    }

    input:focus+.round-ball {
      box-shadow: 0 0 1px var(--primary);
    }

    input:checked+.round-ball:before {
    left: 29px;
    }
}
