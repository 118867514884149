@font-face {
  font-family: "ProximaNova";
  src: url("ProximaNova-Regular.svg") format("svg");
  src: url("ProximaNova-Regular.eot") format("eot");
  src: url("ProximaNova-Regular.ttf") format("truetype");
  src: url("ProximaNova-Regular.woff") format("woff");
  src: url("ProximaNova-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
