

:root {
   --primary:#1295ED;
   --primaryDark:#133352;
   --primaryDarkTransparent:#133352c2;
   --primaryActive:#0e4061 ;
   --borderColor: #213042;
   --gray:#8F97B1;
   --grayText:#A5A5A5;
   --bgScrollbar: #8f97b1;
   --bgSupportMain: #10162B;
   --bgSupportSecondary:#111a33;
   --bgSupportTertiary: #161B2E;
   --bgSupport4:#10182e;
   --bodyBg:#0b1024;
   --bgDropDown:#060b1d;
   --bgModal:#0d1125;
   --bgSearchField:#19253b;
    --bodyText: #fff;
    --progressBar:#3e4253;
    --levelStatusBg:#111a33;
    --violet:#3a6bf2;
    --mobileProgressBg:#0c0f20;
    --tealMain:#35f7bc;
    --bgSportsCard:#111a33;
    --oddBox:#080c19;
    --betSlip:#000a28;
    --grayBgVariant:#434a5e;
  //  gradient
   --secondaryGradient1:#1566a2;
   --secondaryGradient2: #0f4d7e;

   --tertiaryGradient1:#27a8fe;
   --tertiaryGradient2: #688cf2;
    // --darkBlue2: #10162B;
    // --darkBlue4: #151A2D;


  // --primary: #FCD535;
  --bodyText: #fff;
  --success: #15803d;
  --greenLight: #51FC35;
  --white: #fff;
  --bgWhite: #fff;
  --textWhite: #fff;
  --black: #000;
  --textBlack: #000;
  --bgBlack: #000;
  --errorColor: #DC3545;
  --required: #DC3545;
  --dot: #1fff20;
  --lossAmtColor: red;
  --winAmtColor: #98FF59;
  --greenDark: #00e70166;
  --backDrop: rgba(0, 0, 0, 0.5);
  --black80: rgba(0, 0, 0, 0.8);
  --neoShadow1:#24262E;
  --neoShadow2:#040507;
  --neoActiveShadow1:#383C47;
  --shadowPx:-3px -3px 10px 0px;
  --shadowPx2:4px 4px 10px 0px;
  --activeShadowPx:-3px -3px 4.2px 0px;
  --activeShadowPx2:4px 4px 3.1px 0px;
  --checkBoxBorder:#0606065b;

  // border variable
  --borderRadius: 6px;

}
