 @use 'sass:color';

@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    @content;
  }
}

@mixin upToLg {
  @media screen and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin text-lg {
  font-size: 1.125rem;
}

@mixin text-md {
  font-size: 0.875rem;
}

@mixin text-sm {
  font-size: 13px;
}

@mixin PrimaryBtn {
  border-radius: var(--borderRadius);
  background: var(--bgSupportSecondary);
  font-size: 0.875em;
  display: flex;
  gap: 10px;
  padding: 0.5rem 2rem;
  color: var(--bodyText);
  border: none;
  align-items: center;
  background-repeat: no-repeat;
  background-position: left center;
  height: 40px;
}

@mixin SecondaryBtn {
  border-radius: var(--borderRadius);
  background: var(--primary);
  color: var(--white);
  font-size: 13px;
  display: flex;
  gap: 10px;
  padding: 0.5rem 2rem;
  // color: var(--textBlack);
  border: none;
  font-weight: 500;
  align-items: center;
  background-repeat: no-repeat;
  background-position: left center;
  height: 40px;
}

@mixin ClaimBtn {
  border-radius: var(--borderRadius);
  font-size: 0.8em;
  display: flex;
  gap: 10px;
  padding: 0.7rem;
  justify-content: center;
  color: var(--bodyText);
  border: none;
  align-items: center;
  max-width: 120px;
  min-width: 120px;
}

@mixin sidebar {
  margin-top: 0.56rem;
}

@mixin BannerHeading {
  color: var(--bodyText);
  font-size: 1.25em;
  line-height: 1.559rem;
  max-width: 150px;
}

@mixin dropDownBg{
  background-color: var(--bgDropDown);
}

@mixin BannerContent {
  color: var(--bodyText);
  font-size: 0.8em;
  line-height: normal;
  padding: 0.5rem 0 !important;
}


@mixin Inputs {
  border-radius: var(--borderRadius);
  background: var(--bgSupportTertiary);
  border: 1px solid transparent;
  min-height: 39px;
  max-height: 39px;
  font-size: 0.875em;
  padding: 5px 20px;
  color: var(--bodyText);

  &:focus {
    box-shadow: none;
    border-color: var(--primary);
  }
}


@mixin Ellipse {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60%;
  overflow: hidden;
}


@mixin TabsBg {
  border-radius: var(--borderRadius);
  background: var(--bodyBg);
  padding: 0.31rem;
}


@mixin TabAnchor {
  @include text-sm();
  font-style: normal;
  line-height: normal;
  display: flex;
  gap: 0.5rem;
  text-decoration: none;
  padding: 5px 10px;
  height: 40px;
  align-items: center;
}

.gradient-btn {
  @include SecondaryBtn();
  box-shadow: none;

}
@mixin textMuted{
  color: var(--gray);
}
@mixin primaryGradient{
  background: linear-gradient(269.91deg, #1295ED -3.59%, #213043 99.92%);
}
@mixin secondaryGradient{
  background: linear-gradient(269.91deg, var(--secondaryGradient1) -3.59%, var(--secondaryGradient2) 99.92%);
}
@mixin mobileFooterGradient{
  background: linear-gradient(269.91deg, #1d2f44 -3.59%, #18273e 99.92%);
}
@mixin suitcaseGradient{
  background: linear-gradient(269.91deg, #1295ed -3.59%, #0069eb 99.92%);
}
@mixin trophyGradient{
  background: linear-gradient(269.91deg, #3a6bf2 -3.59%, #0044ff 99.92%);
}
@mixin mobileProgressGradient{
  background: linear-gradient(180deg, #0e213b 28%, #134370 75%);
}
@mixin achievementGradient {
  background: linear-gradient(90deg, #1295ed -3.59%, #0069eb 99.92%);
}
@mixin quickBetGradient {
  background: linear-gradient(90deg, #1295ed -3.59%, #0069eb 50.92%, #DC3545  99.92%);
}

@mixin neuomorphism {
  // background: var(--bodyBg);
  // box-shadow: var(--shadowPx) var(--neoShadow1), var(--shadowPx2) var(--neoShadow2);
}
@mixin activeNeuomorphism {
  // background: var(--primary);
  // box-shadow: var(--activeShadowPx) var(--neoActiveShadow1), var(--activeShadowPx2) var(--neoShadow2) !important;
}
@mixin innerLightNeuomorphism {
  // box-shadow: -2px -2px 4px 0px #FFF inset, 2px 2px 4px 0px rgba(180, 193, 213, 0.60) inset;

}
@mixin innerLightInputNeuomorphism {
  // background: var(--bodyBg);
  // box-shadow: -2px -2px 4px 0px #FFF inset, 2px 2px 4px 0px rgba(180, 193, 213, 0.60) inset;

}
@mixin innerLightFocusInputNeuomorphism {
  // background: var(--bodyBg) !important;
  // border-color: transparent !important;
  // box-shadow: -4px -4px 4px 0px #FFF inset, 3px 3px 4px 0px rgba(180, 193, 213, 0.60) inset !important;

}

@mixin boxShadow {
  box-shadow: 0 8px 30px rgb(0,0,0,0.12);
    border: 1px solid var(--borderColor);
}

@mixin borderCursor {
  border: 1px solid var(--borderColor);
  border-bottom: 0;
  border-right: 0;
}

@mixin animationBlink{
  background-color: var(--bgSupport4);
  animation: blink 2s infinite linear;
  @keyframes blink {
    0% {
     opacity: 0.25;
  }

  30% {
      opacity: 0.5;
  }
  70% {
      opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
  }
}
@mixin animationShake{
  animation: shake 0.3s infinite linear ;
  @keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(0.25deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-0.25deg); }
    100% { transform: rotate(0deg); }
  }
}
