.locked-bonus-detail {
  .content-wrapper {
    min-height: 300px;

    .overview-wrapper {
      .scrollable-content {
        height: 300px;
        overflow-y: auto;
        padding-right: 10px;
      }

      .header {
        padding: 14px 10px;
        border-radius: 6px;
        border-bottom: 1px solid var(--borderColor);
        background-color: var(--bgSupportSecondary);

        span {
          font-size: 14px;
        }
      }

      .single-info {
        padding: 14px 10px;
        border-bottom: 1px solid var(--borderColor);

        &:last-child {
          border-bottom: none;
        }


        .text-muted {
          font-size: 13px;
        }

        .amount {
          text-align: end;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .refresh-icon{
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid var(--borderColor);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
