@use '../components/mixin' as *;
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

html {
  scroll-margin-block: 250px;
}

.container {
  max-width: 1200px !important;
}

.container {
  max-width: 1200px !important;
}


.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.mobile-below-hide {
  display: block;

  @include mobile {
    display: none;
  }
}

.mobile-up-hide {
  display: none;

  @include mobile {
    display: block;
  }
}

html {
  body {
    background-color: var(--bodyBg);
    font-family: "Rubik", sans-serif;
    color: var(--bodyText);
  }

  &.korean {
    body {
      font-family: "Noto Sans KR", sans-serif;
      font-optical-sizing: auto;
    }

  }

}

.loading-image {
  @include animationBlink;
}

.logo {
  max-width: 200px;
  min-width: 115px;
  width: unset !important;

  &.light {
    filter: invert(1) !important;

  }
}

.react-datepicker>* {
  font-family: "Rubik", sans-serif !important;
}

p {
  font-size: 14px;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: var(--bodyText);
  }
}



*:focus-visible,
*:focus {
  outline: none
}

.Toastify {
  .Toastify__toast-container {
    .Toastify__progress-bar {
      height: 3px;
    }

    .Toastify__toast-theme--colored.Toastify__toast--warning {
      .Toastify__toast-body {
        .Toastify__toast-icon {
          background-color: #ffc107 !important;
        }
      }
    }

    .Toastify__toast-theme--colored.Toastify__toast--success,
    .Toastify__toast-theme--colored.Toastify__toast--error,
    .Toastify__toast-theme--colored.Toastify__toast--warning {
      // background-color: #61dafb;
      background: var(--bodyBg);
      mix-blend-mode: normal;
      border-radius: 0.25rem;
      min-height: 80px;
      overflow: hidden;
      min-width: 100%;
      padding: 0;

      // padding-bottom: 130px !important;
      .Toastify__close-button>svg {
        width: 18px;
        height: 18px;
        fill: var(--white);
        margin-right: 10px;
        position: absolute;
        right: 0px;
        top: 10px;
      }

      .Toastify__toast-body {
        position: relative;
        left: 0;
        display: flex;
        width: 100%;
        overflow: hidden;
        // justify-content: center;
        align-items: center;
        // flex-direction: column-reverse;
        color: var(--bodyText);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        padding: 0;
        margin: 0;

        .toast-title {
          // height: 100px;
          display: flex;
          // justify-content: center;
          font-size: 18px;
          font-weight: 800;
        }

        .toast-title+span {
          text-align: left !important;
        }

        .Toastify__toast-icon+div {
          display: flex;
          justify-content: center;
          flex-direction: column;
        }

        .Toastify__toast-icon {
          background: var(--required);
          height: 100%;
          padding: 0;
          margin-top: 0;
          margin-right: 1rem;
          width: 80px;
          border-radius: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          svg {
            color: var(--white) !important;
            border-radius: 50%;
            /* width: 36px !important; */
            // position: absolute;
            /* height: 36px; */
            top: 0;
            left: 0;
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            right: 0;
            bottom: 0;
            padding: 1.7rem;
          }
        }
      }
    }

    .Toastify__toast-theme--colored.Toastify__toast--success {
      .Toastify__toast-body {
        .Toastify__toast-icon {
          background: var(--success);
        }
      }

    }

    .Toastify__toast-theme--colored.Toastify__toast--success {
      .Toastify__toast-icon {
        background: #74e861;
      }
    }
  }
}



@media screen and (max-width: 992px) {
  .container {
    max-width: 100%;
    padding: 0 3vw;
  }
}

@include mobile {
  .container {
    max-width: 100%;
    padding: 0 3vw;
  }
}

@media screen and (max-width: 576px) {
  .container {
    max-width: 100%;
    padding: 0 3vw;
  }
}

.card-grid {
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
  gap: 18px 12px;

  &.hot-new {
    @media screen and (max-width: 1199px) {
      grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
    }

    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
    }

  }

  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
  }

  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
  }


}

.go-home-btn button {
  @include SecondaryBtn;
  margin: 0 auto;
}

.cursor-pointer {
  cursor: pointer;
}

.error-message {
  color: var(--errorColor);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: .228px;
  line-height: 14px;
  margin-top: 11px;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

table tr th {
  padding: 1rem;
  color: var(--gray);
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  word-wrap: break-word;
  white-space: nowrap;
  vertical-align: middle;
  border-bottom: 1px solid var(--borderColor);

  @include mobile {
    font-size: 13px;
    padding: 0.75rem 0.5rem;
  }
}

table tbody tr td:first-child {
  border-top-left-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
}

table tbody tr {
  background-color: var(--bgSupportSecondary);
}

table tr td {
  padding: 0.95rem 0.6rem;
  word-wrap: break-word;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 0.9rem;

  @include mobile {
    font-size: 13px;
    padding: 0.75rem 0.5rem;
  }
}

.form-group {
  margin-bottom: 10px;
}

.not-found-box {
  grid-column: 1 / -1;
  p {
    font-size: 14px;
    color: var(--gray);
  }
}

.label {
  font-size: 14px;
}

.text-success {
  color: var(--winAmtColor) !important;
}

.tooltip-arrow::before {
  border-top-color: yellow !important;
}

.tooltip-inner {
  background-color: yellow;
  color: #000;
}

.modal-content {
  background: var(--bgModal);
}

.btn-close {
  background: url('../../../public/icon/close.svg');
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: 16px !important;
  background-position: center;
  filter: invert(0) !important;
}

.dark {
  .hidden-icon {
    filter: unset;
  }
}

.light {
  .hidden-icon {
    filter: brightness(0.2);
  }

  .invert_icon {
    filter: invert(1);
  }

  table tr td {
    color: var(--lightGray);
  }

  input:not([type="file" i], [type="image"], [type="checkbox"], [type="radio"]),
  select {
    @include innerLightInputNeuomorphism;

    &:focus {
      @include innerLightFocusInputNeuomorphism();

    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px var(--bodyBg) inset !important;
    /* Change the color to your own background color */
    -webkit-text-fill-color: var(--bodyText) !important;
    border: 1px solid var(--primary) !important;
  }

  .modal-header img,
  .btn-close {
    filter: invert(1) !important;
  }

  .Toastify {
    .Toastify__toast-container {

      .Toastify__toast-theme--colored.Toastify__toast--success,
      .Toastify__toast-theme--colored.Toastify__toast--error,
      .Toastify__toast-theme--colored.Toastify__toast--warning {

        .Toastify__close-button>svg {
          filter: invert(1);
        }


      }




    }
  }

}

.accordion-button {
  transition: unset;
}

.card-grid .card-image {
  padding: 0;
}

.overlay-text {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  font-weight: bold;
  border-radius: 10px;
  background-color: var(--primary);
  z-index: 1;
  opacity: 0.65;

}

.view-all-text {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--black);
  font-weight: 600;

  &.light {
    color: var(--white);
  }
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1 !important;
}

.loss-amt {
  color: var(--lossAmtColor);
  font-weight: 600;
}

.win-amt {
  color: var(--primary);
  font-weight: 600;
}

.all-content {
  padding: 11px 18px;
  color: var(--primary);
  text-transform: uppercase;
  @include text-sm;
  position: relative;
  line-height: 1;
  font-weight: 600;

  &:hover {
    opacity: 0.9;
    color: var(--primary);
  }
}



.pagination-wrapper {
  list-style: none;
  padding: 0;

  .pagination-item-style {
    border: 1px solid var(--borderColor);
    border-radius: 6px;
    cursor: pointer;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      padding: 8px 10px;
      min-width: 45px;

      @include mobile {
        padding: 4px 7px;
        min-width: 24px;
        font-size: 11px;
      }
    }

    &.disabled-item {
      opacity: 0.5;
    }

    &:hover,
    &.active-item {
      background-color: var(--primary);
      color: var(--white);
    }

  }
}

.error-boundary {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.progress-content {
  margin-bottom: 40px;

  .text-val {
    font-size: 12px;
    font-weight: 500;
  }

  .progress-main-wrapper {
    position: relative;
    cursor: pointer;

    &:hover {
      .overlay-text-custom {
        opacity: 1;
      }
    }

    .progress-bonus-wrapper {
      position: relative;
      height: 12px;
      width: 100%;
      position: relative;
      border-radius: 50px;
      background-color: var(--progressBar);
      overflow: hidden;

      .progress-line {
        background: var(--primary);
        height: 100%;

      }

    }

    .overlay-text-custom {
      @include tablet {
        opacity: 1;
      }

      opacity: 0;
      position: absolute;
      bottom: 20px;
      padding: 6px 10px;
      border-radius: 6px;
      background-color: var(--primary);
      font-size: 12px;
      font-weight: 600;
      z-index: 12;
      // color: var(--textBlack);
      transform: translateX(-50%);
      text-align: center;
      min-width: 56px;

      &::after {
        content: "";
        position: absolute;
        background: var(--primary);
        width: 8px;
        height: 8px;
        transform: rotate(45deg) translateX(-50%);
        bottom: -7px;
        z-index: 13;
        left: 50%;
      }
    }
  }
}

.dropdown-item.active {
  background-color: var(--primaryActive);
  color: var(--bodyText) !important;
}

.arrow-mark {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  background-color: var(--bgDropDown);
  @include borderCursor;
  z-index: -1;

}

label {
  &.custom-switch {
    color: var(--white) !important;
  }
}

#my_custom_intercom_launcher {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 80px;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 40;
  background: var(--primary);

  @include mobile {
      bottom: 60px;
      right: 6px;
    }
}

.intercom-messenger-frame {
  bottom: 80px !important;
}

@media (max-width: 768px) {
  .intercom-messenger-frame {
    inset: unset !important;
    bottom: 0 !important;
    left: 40px !important;
    width: calc(100% - 40px) !important;
    height: 68% !important;
    border-radius: 20px !important;
  }
    .intercom-lightweight-app-launcher{
      bottom: 100px !important;
      right: 12px !important;

    }

  html.light.intercom-mobile-messenger-active {
    overflow: unset !important;
  }

  html.dark.intercom-mobile-messenger-active {
    overflow: unset !important;
  }

}

 .intercom-messenger-frame {
   bottom:80px !important;
 }
 @media (max-width: 768px) {
   .intercom-messenger-frame {
     inset: unset !important;
     bottom: 0 !important;
     left: 40px !important;
     width: calc(100% - 40px) !important;
     height: 68% !important;
     border-radius: 20px !important;
   }

   html.light.intercom-mobile-messenger-active {
     overflow: unset !important;
   }

   html.dark.intercom-mobile-messenger-active {
     overflow: unset !important;
   }

 }
 .text-sm{
  font-size: 14px;
 }

 .voucher-slide-container{
  overflow: hidden;
  .voucher-slider{
    display: flex;
    transition:  all 0.3s ease;
    &>div {
        width: 100%;
        flex-shrink: 0;
      }
    &.active {
      transform: translateX(-100%);
    }
  }
  .unique-code-bonus-list{
    max-height: 480px;
    overflow-y: auto;
    padding-right: 10px;
    max-width: 500px;
   ul{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 4px;
    li{
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      padding: 12px 10px;
      border-radius: 10px;
      color: var(--white);
      font-size: 14px;
      cursor: pointer;
      border: 1px solid var(--borderColor);
      &:hover {
        background-color: var(--primaryActive);
      }
      .icon-check{
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        background-color: var(--progressBar);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: var(--white);
        border-radius: 50%;

      }

    }

   }

  }


 }

 .accordionSection {
   margin: 1rem 0;

   .accordion-body {
     @include mobile {
       padding: 10px;
     }

     ul {
       list-style: none;
       display: flex;
       gap: 8px;
       margin: 0;
       padding: 0;
       flex-wrap: wrap;
     }
   }

   .accordion-header {
     background-color: transparent;

     button {
       background-color: var(--bgSupport4);
       border-radius: var(--borderRadius);
       color: var(--bodyText);
     }
   }

   .accordion-item {
     background-color: var(--bgSupport4);
     border: 0;
     margin-bottom: 1rem;
     border-radius: var(--borderRadius);
    color: var(--bodyText);
   }

   .accordion-button:not(.collapsed) {
     background-color: transparent;
     box-shadow: none !important;

   }

   .accordion-button {
     @include mobile {
       padding: 10px;
     }

     &:focus {
       box-shadow: none !important;
     }

     &::after {
       content: ' ';
       background: url('../../assets/sports-icon/caret.svg');
       background-repeat: no-repeat;
     }
   }
 }
 .bonus-badge{
   background-color: var(--primary);
   color: var(--white);
   border-radius: 50px;
   padding: 5px 10px;
   font-size: 12px;
   font-weight: 600;
   border: none;
 }
 .tooltip-content{
  background-color: var(--bgSupport4) !important;
  opacity: 1 !important;
  padding: 10px 10px 20px !important;
  z-index: 100000;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--borderColor);
 }

 .custom-range {
  width: 100%;
  cursor: pointer;
  padding: 15px 0 0 !important;
  appearance: none;
  background: transparent;
}

.custom-range::-webkit-slider-runnable-track {
  height: 4px;
  border-radius: 8px;
}

.custom-range::-moz-range-track {
  height: 4px;
  border-radius: 8px;
}

.custom-range::-ms-track {
  height: 4px;
  border-radius: 8px;
}

// .custom-range::-webkit-slider-thumb {
// position: relative;
// top:-6px
// }

// .custom-range::-moz-range-thumb {
//   position: relative;
//   top:-6px
// }

// .custom-range::-ms-thumb {
// position: relative;
// top:-6px
// }
.tooltip-content{

}
