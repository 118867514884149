@use '../components' as *;


.accept-terms-link{
  transition: all 0.3s ease;
  // color: var(--bs-link-color);
  text-decoration: underline;
}
.chips,
.chips:hover {
  background-color: var(--bgSupportTertiary);
  border-radius: var(--borderRadius);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bodyText);
  text-decoration: none;
  gap: 10px;
  font-size: 12px;
  cursor: pointer;
}

.filter-chips {
  display: flex;
  gap: 15px;
  margin-bottom: 1rem;
  overflow-x: auto;

  .chips {
    white-space: nowrap;
  }

  .clear-filter {
    @include SecondaryBtn();
    box-shadow: none;
    text-decoration: none;
  }
  #signinmodal.fade:not(.show){
    opacity: 1;
  }
}

.react-tel-input .flag-dropdown {
  border: 3px solid transparent;
  border-radius: var(--borderRadius);
  background-color: transparent !important;
  color: var(--bodyText);
}

.dial-code {
  color: var(--white) !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: var(--primaryActive) !important;
}


.react-tel-input .country-list {
  box-shadow: none;
  background-color: var(--bodyBg);
  border-radius: 6px;
  @include boxShadow;
}

.react-tel-input .country-list .search {
  background-color: var(--bodyBg);
}

.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country:hover {
  background-color: var(--primaryActive);
  color: var(--textWhite) !important;
}


.react-tel-input .country-list .search {
  padding: 10px;
  left: 0;

  .search-emoji {
    display: none;
  }
}
.react-tel-input .country-list .country .dial-code {
  color: var(--gray) !important;
}

.react-tel-input .country-list .search-box {
  width: 100%;
  margin-left: 0;
  border-radius: var(--borderRadius);
  border: 1px solid transparent;
}


.react-tel-input .form-control {
  padding-left: 50px !important;
  border: none;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}
