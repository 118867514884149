::-webkit-scrollbar-track {
    background-color:var(--bgSupportTertiary);
}
::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}
::-webkit-scrollbar-thumb {
    background-color: var(--bgScrollbar);
    border-radius: var(--borderRadius);
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow:0 0 0 50px var(--bgSupportTertiary)  inset !important; /* Change the color to your own background color */
  -webkit-text-fill-color: white !important;
  border:none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
